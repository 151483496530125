export default {
copy_one: 'Clique em copiar',
copy_ok: 'Copiado com sucesso',
zdl: 'Eu entendo',
fen: 'Pontos',
fen_yue: 'Saldo de pontos',
maifen: 'Comprar pontos',
pay_jine: 'Valor da compra',
pay_shurujine: 'Digite o valor da compra',
pay_huode: 'Ganhe pontos',
pay_fangshi: 'Selecione o método de pagamento',
pay_shuoming: 'Instruções de pagamento',
pay_xiane: 'Limite de pagamento',
pay_xiane_chao: 'Limite de pagamento não atingido!',
pay_tijiao: 'Enviar pagamento',
pay_queren: 'Confirmar pagamento',
pay_usdt: 'Pagamento com criptomoeda',
pay_usdt_address: 'Endereço de pagamento',
pay_usdt_token: 'Transferir token',
pay_usdt_chain: 'Rede',
pay_usdt_tip: 'Após o recebimento da recarga, ela será convertida automaticamente em pontos de jogo de acordo com a taxa de câmbio',
pay_usdt_done: 'Transferência concluída',
pay_pix: 'Pagamento via PIX',
pay_pix_tip: 'Por favor, complete o pagamento em 10 minutos',
pay_pix_qr: 'Escaneie o QR code',
pay_pix_copy: 'Copiar o código do pix',
pay_pix_faq: 'Como pagar',
pay_pix_faq_ok: 'Abra seu aplicativo do banco ou de pagamento, vá até a área PIX, selecione "PIX Copia e Cola " ou "Ler QR code" e complete o pagamento!',
pay_pix_done: 'Pagamento concluído',
shangfen: 'Adicionar pontos',
shangfen_tip: 'Por favor, entre em contato com o agente superior para adicionar pontos.',
xiafen: 'Dedução de pontos',
xiafen_shuliang: 'Número de pontos deduzidos',
xiafen_shuliang_shuru: 'Digite a quantidade de pontos para resgatar',
zhuanfen: 'Transferir pontos',
zhuanfen_to_wanjia: 'Transferir pontos para o jogador',
zhuanfen_to_daili: 'Transferir pontos para o agente',
zhuanfen_t2: 'Quantidade de pontos para transferência',
zhuanfen_shuru: 'Digite a quantidade de pontos para transferir',
zhuanfen_done: 'Transferência concluída',
jianfen: 'Dedução de pontos',
jianfen_t2: 'Dedução de pontos',
jianfen_shuru: 'Insira os pontos de dedução',
jianfen_chenggong: 'Dedução concluída',
daiban: 'Pendente',
xinzeng: 'Adicionar novo',
zhuanru: 'Entrada de transferência',
zhuanru_zong: 'Total transferido',
zhuanchu: 'Saída de transferência',
zhuanchu_zong: 'Total de saídas de transferência',
zhuanchu_jing: 'Saídas líquidas de transferência',
jine: 'Dinheiro',
jine_zong: 'Valor total',
youxi: 'Jogo',
ren: 'Pessoa',
genggai: 'Trocar',
tijiao: 'Enviar',
tijiao_shijian: 'Hora do envio',
wancheng: 'Sucesso',
jujue: 'Rejeitar',
jujue_queren: 'Confirmar a rejeição?',
tongguo: 'Aprovar',
tongguo_queren: 'Confirmar aprovação?',
quxiao: 'Cancelar',
queding: 'Confirme',
index: 'Início',
login: 'Entrar',
login_last: 'Último login',
login_heying: 'Painel do Agente',
reg: 'Registrar',
reg_liji: 'Inscreva-se agora',
reg_time: 'Hora de registro',
reg_heying: 'Registrar',
reg_wait01: 'Sua conta está aguardando aprovação...',
reg_wait02: 'Entre em contato com o suporte da Yelobet para processamento:',
lianxi_fangshi: 'Informações de contato',
lianxi_zhanghao: 'Detalhes de contato',
tuiguang_fangan:  'Quais estratégias de marketing você tem?',
tuiguang_fangan_tip:  'Descreva seu plano de marketing',
kefu: 'Serviço ao Cliente',
kefu_tg: 'Telegram Support',
kefu_ws: 'WhatsApp Support',
shuru_ninde: 'Digite seu ',
zhanghao: 'Conta',
zhanghao_yiyou: 'Você já tem uma conta?',
zhanghao_meiyou: 'Não tem uma conta ainda?',
setup: 'Configurações',
touxiang: 'Foto de perfil',
touxiang_xiugai: 'Modificar avatar',
huobi: 'Moeda',
logout: 'Sair',
username: 'Nome de usuário',
password: 'Senha',
password_genggai: 'Mudar senha',
password_yuanshi: 'Senha original',
password_xin: 'Nova senha',
code: 'Código do convite',
beizhu: 'Observações',
beizhu_jine: 'Quantidade de observações',
beizhu_neirong: 'Observações',
gengduo: 'Ver mais',
gengduo_jiazai: 'Carregar mais',
qingxuanze: 'Por favor, selecione',
sousuo_tip: 'Procurar nome de usuário/UID',
dingdanbianhao: 'Número do pedido',
chuangjianchenggong: 'Criado com sucesso',
zanwushuju: 'Atualmente não há dados disponíveis',
xiangqing: 'Detalhes',
shuliang: 'Quantidade',
xiangxibaobiao: 'Relatório detalhado',
shuru: 'Entrada',
laizi: 'Vem de',
zhuangtai: 'Estado',
chulizhong: 'Processando',
chulizhong_shangji: 'Enviado, aguardando processamento superior',
yiwancheng: 'Concluído',
shibai: 'Falhar',
wanjia: 'Jogador',
wanjia_wode: 'Meus jogadores',
wanjia_xuanze: 'Selecionar jogador',
wanjia_shuju: 'Dados do jogador',
wanjia_liebiao: 'Lista de jogadores',
wanjia_xinzeng: 'Novos jogadores',
wanjia_xiangqing: 'Detalhes do jogador',
wanjia_xinxi: 'Informações do jogador',
daili: 'Agente',
daili_wode: 'Meus agentes',
daili_xuanze: 'Selecionar agentes',
daili_shuju: 'Estatísticas de agentes',
daili_liebiao: 'Lista de agentes',
daili_xinzeng: 'Adicionar agente',
daili_xiangqing: 'Detalhes do agente',
daili_xinxi: 'Informações do agente',
bianji: 'Editar',
bianji_ziliao: 'Editando informações',
bianji_chenggong: 'Modificado com sucesso',
jilu: 'Gravar',
jilu_lishi: 'Histórico',
jilu_jinqi: 'Registros recentes',
jilu_qingkong: 'Limpar registros',
dates: 'Data',
dates_today: 'Hoje',
dates_7: '7 dias',
dates_30: '30 dias',
dates_shaixuan: 'Filtrar datas',
youxi_shijian: 'Tempo',
youxi_touzhu: 'Apostas',
youxi_mingcheng: 'Jogo',
youxi_paicai: 'Pagamento',
qsryhm: 'Por favor, digite seu nome de usuário',
qsrmm: 'Por favor, digite sua senha',
qsrlxzh: 'Por favor, insira sua conta de contato',
juli: 'Exemplo',
jzgd: 'Mais',
about_gg: 'Nova Oportunidade para Alavancar Seus Ganhos com Cassinos Online!',
about_tt: 'Recrutamento de agentes',
about_tip01: 'Ganhe facilmente',
about_tip02: 'até',
about_tip03: 'de lucro',
about_btn: 'REGISTRAR AGORA',
about_a01: 'Nova Oportunidade para Alavancar Seus Lucros com Cassinos Online!',
about_a02: 'Quer ganhar ainda mais dinheiro com o fenômeno dos cassinos on-line? Você tem uma grande oportunidade à sua frente.',
about_a03: 'Você pode comprar 100 pontos por R$ 20 e, posteriormente, vendê-los aos jogadores na proporção de R$ 1:1. Você já sabe que os jogadores acabarão perdendo dinheiro durante longos períodos de jogo. Quando isso acontecer, você ganhará dinheiro!',
about_a04: 'Por exemplo, se um de seus jogadores comprar de você 100 pontos por R$ 100 e perder, você terá um lucro de R$ 80, já que seu gasto inicial foi de apenas R$ 20',
about_b01: 'Contrate Agentes para Vender para Você!',
about_b02: 'Você pode ir ainda mais fundo e fazer com que outras pessoas vendam para você.',
about_b03: 'Como agente de nível 1, você tem o poder de adquirir 100 pontos ao custo de R$ 20. Você pode recrutar novos jogadores e vender pontos para eles.',
about_b04: 'Mas você pode ir além e expandir sua rede nomeando agentes secundários para os quais você pode vender pontos a uma taxa de R$ 25 por 100 pontos.',
about_b05: 'Essa taxa pode ser negociada entre os agentes primários e secundários. Entretanto, seus agentes secundários estão restritos a comprar pontos exclusivamente de você (o agente superior). O sucesso de seus agentes secundários em atrair mais jogadores se traduz diretamente no aumento das vendas de pontos, elevando assim suas margens de lucro.',
about_b06: 'Com isso, você está aumentando seus ganhos exponencialmente à medida que continua contratando novos agentes para vender para você!',
}