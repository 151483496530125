import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
import 'typeface-roboto/index.css'    // 引入本地的Roboto字体资源
import '@mdi/font/css/materialdesignicons.css'  // 引入本地的Material Design Icons资源

Vue.use(Vuetify);

export default new Vuetify({
    icons:{
        iconfont: 'mdi',    // 设置使用本地的icon资源
    },
    theme: { 
        options: {
            customProperties: true,
          },
        themes: {
            light: {
                primary: '#1E2329',
                app_bg: '#f5f5f5',
                nav_bg: '#FCD535',
                btn_bg: '#2C2C63',
            },
            dark: {
                primary: '#26A32F',
                app_bg: '#24262B',
                nav_bg: '#222',
                btn_bg: '#32353b',
            },
        },
    },
});
