import axios from 'axios'
import router from '../router/'
import { Encrypt, Decrypt } from '@/utils/aes'
import {getStorage, removeStorage, getDeviceId} from "../module/storage"

axios.defaults.timeout = 10000;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
//axios.defaults.withCredentials = true; //让ajax携带cookie，但是不能设置 * 跨域，必须是具体域名
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
//http request 拦截器
axios.interceptors.request.use(
  config => {
    //config.data = Encrypt(config.data)
    return config;
  },
  error => {
    return Promise.reject(err);
  }
);

//响应拦截器即异常处理
axios.interceptors.response.use(response => {
    //response.data.data = JSON.parse(Decrypt(response.data.data))
    //console.log(response.data);
    //console.log(response.status);
    //console.log(response.statusText);
    //console.log(response.headers);
    //console.log(response.config);
    return response
}, error => {
    console.log(`${error.response}`)
    if (error.response) {
        /*
        switch (error.response.status) {
            case 401:
                removeStorage("token")
                router.push('/auth/wait')
                break;
            case 403:
                removeStorage("token")
                router.push('/home')
                router.go(0)
                break;
            default:
                break;
        }
        */
    }
    return Promise.reject(error)
})

export function post(url,data = {}){
    data.lang = getStorage('Lang')
    data.k = Encrypt(new Date().getTime().toString())
    return new Promise((resolve,reject) => {
        axios.post(url,JSON.stringify(data),{
            headers:{
                'Content-Type': 'application/json;charset=UTF-8',
                'XX-Token': getStorage("token"),
                'XX-Device-Id': getDeviceId()
            }
        })
        .then(response => {
            resolve(response.data);
        },err => {
            reject(err)
        })
   })
}

export function upload(url,data = {}){
    return new Promise((resolve,reject) => {
        axios.post(url,data,{
            headers:{
                'Content-Type': 'multipart/form-data',
                'XX-Token': getStorage("token")
            }
        })
        .then(response => {
            resolve(response.data);
        },err => {
            reject(err)
        })
   })
}

export const server = {
    test: function(paramObj){
        return post('/api/index/gameinfo',paramObj);
    },
    uploadtest: function(paramObj){
        return upload('/api/Mcenter/test',paramObj);
    },
    reg: function(paramObj){
        return post('/index/reg',paramObj);
    },
    login: function(paramObj){
        return post('/index/login',paramObj);
    },
    loginbys: function(paramObj){
        return post('/index/loginbys',paramObj);
    },
    conf: function(paramObj){
        return post('/index/conf',paramObj);
    },
    modifyinfo: function(paramObj){
        return post('/index/modifyinfo',paramObj);
    },
    addagent: function(paramObj){
        return post('/index/addagent',paramObj);
    },
    addplayer: function(paramObj){
        return post('/index/addplayer',paramObj);
    },
    edituser: function(paramObj){
        return post('/index/edituser',paramObj);
    },
    myinfo: function(paramObj){
        return post('/index/myinfo',paramObj);
    },
    recentagents: function(paramObj){
        return post('/index/recentagents',paramObj);
    },
    agentlist: function(paramObj){
        return post('/index/agentlist',paramObj);
    },
    myagents_dashboard: function(paramObj){
        return post('/index/myagents_dashboard',paramObj);
    },
    recentplayers: function(paramObj){
        return post('/index/recentplayers',paramObj);
    },
    playerlist: function(paramObj){
        return post('/index/playerlist',paramObj);
    },
    myplayers_dashboard: function(paramObj){
        return post('/index/myplayers_dashboard',paramObj);
    },
    agentinfo: function(paramObj){
        return post('/index/agentinfo',paramObj);
    },
    playerinfo: function(paramObj){
        return post('/index/playerinfo',paramObj);
    },
    transfer: function(paramObj){
        return post('/index/transfer',paramObj);
    },
    cashout: function(paramObj){
        return post('/index/cashout',paramObj);
    },
    agentdata: function(paramObj){
        return post('/index/agentdata',paramObj);
    },
    playerdata: function(paramObj){
        return post('/index/playerdata',paramObj);
    },
    cashout_wait_count: function(paramObj){
        return post('/index/cashout_wait_count',paramObj);
    },
    cashout_wait: function(paramObj){
        return post('/index/cashout_wait',paramObj);
    },
    cashout_allow: function(paramObj){
        return post('/index/cashout_allow',paramObj);
    },
    cashout_deny: function(paramObj){
        return post('/index/cashout_deny',paramObj);
    },
    last10log: function(paramObj){
        return post('/index/last10log',paramObj);
    },
    agentlast10log: function(paramObj){
        return post('/index/agentlast10log',paramObj);
    },
    playerlast10log: function(paramObj){
        return post('/index/playerlast10log',paramObj);
    },
    depositlog: function(paramObj){
        return post('/index/depositlog',paramObj);
    },
    transferlog: function(paramObj){
        return post('/index/transferlog',paramObj);
    },
    cashoutlog: function(paramObj){
        return post('/index/cashoutlog',paramObj);
    },
    receivelog: function(paramObj){
        return post('/index/receivelog',paramObj);
    },
    sendlog: function(paramObj){
        return post('/index/sendlog',paramObj);
    },
    betlog: function(paramObj){
        return post('/index/betlog',paramObj);
    },
    deposit: function(paramObj){
        return post('/index/deposit',paramObj);
    },
    deposit_pix: function(paramObj){
        return post('/index/deposit_pix',paramObj);
    },
    withdraw: function(paramObj){
        return post('/index/withdraw',paramObj);
    },
}