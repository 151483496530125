<template>
  <v-app :style="{background: $vuetify.theme.themes.dark.background, color: $vuetify.theme.themes.dark.color}">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({

  }),
  watch: {  
 
  },
  created() {
    this.$i18n.locale = 'pt'
    this.$vuetify.lang.current = 'pt'
  },
  mounted() {

  },
  methods: {
  }
};
</script>
<style>
/*.v-application--wrap, .v-sheet{
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}*/
@media screen and (min-width: 960px) {
  .v-main, .w-600 {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}
.v-dialog{
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0), 0px 24px 38px 3px rgba(0, 0, 0, 0), 0px 9px 46px 8px rgba(0, 0, 0, 0) !important;
}
</style>