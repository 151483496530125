export default {
  copy_one: '一键复制',
  copy_ok: '复制成功',
  zdl: '知道了',
  fen: '分',
  fen_yue: '分数余额',
  maifen: '买分',
  pay_jine: '购买金额',
  pay_shurujine: '输入购买金额',
  pay_huode: '获得分数',
  pay_fangshi: '选择支付方式',
  pay_shuoming: '支付说明',
  pay_xiane: '支付限额',
  pay_xiane_chao: '不符合支付限额!',
  pay_tijiao: '提交支付',
  pay_queren: '支付确认',
  pay_usdt: '虚拟货币支付',
  pay_usdt_address: '收款地址',
  pay_usdt_token: '转账币种',
  pay_usdt_chain: '转账网络',
  pay_usdt_tip: '充值到账后会自动根据汇率转换为游戏分数',
  pay_usdt_done: '完成转币',
  pay_pix: 'PIX钱包支付',
  pay_pix_tip: 'Please complete the payment within 10 minutes',
  pay_pix_qr: 'Scan the QR code',
  pay_pix_copy: 'Copy PIX CODE',
  pay_pix_faq: 'How do I pay',
	pay_pix_faq_ok: 'Open your bank or payment mobile app, navigate to the PIX Area, select "Pix Copy and Paste" or "Read QR code" and complete the payment!',
  pay_pix_done: 'Payment Completed',
  shangfen: '上分',
  shangfen_tip: '请联系上级代理上分。',
  xiafen: '下分',
  xiafen_shuliang: '下分数量',
  xiafen_shuliang_shuru: '输入下分数量',
  zhuanfen: '转分',
  zhuanfen_to_wanjia: '转分给玩家',
  zhuanfen_to_daili: '转分给代理',
  zhuanfen_t2: '转账分数',
  zhuanfen_shuru: '输入转账分数',
  zhuanfen_done: '转分成功',
  jianfen: '减分',
  jianfen_t2: '扣减分数',
  jianfen_shuru: '输入扣减分数',
  jianfen_chenggong: '扣减成功',
  daiban: '代办',
  xinzeng: '新增',
  zhuanru: '转入',
  zhuanru_zong: '总转入',
  zhuanchu: '转出',
  zhuanchu_zong: '总转出',
  zhuanchu_jing: '净转出',
  jine: '金额',
  jine_zong: '总金额',
  youxi: '游戏',
  ren: '人',
  genggai: '更改',
  tijiao: '提交',
  tijiao_shijian: '提交时间',
  wancheng: '完成',
  jujue: '拒绝',
  jujue_queren: '拒绝',
  tongguo: '通过',
  tongguo_queren: '通过',
  quxiao: '取消',
  queding: '确定',
  index: '首页',
  login: '登录',
  login_last: '最后登录',
  login_heying: '合营伙伴登录',
  reg: '注册',
  reg_liji: '立即注册',
  reg_time: '注册时间',
  reg_heying: '合营伙伴注册',
  reg_wait01: '您的账户正在等待审批中...',
  reg_wait02: '请联系客服专员加速处理：',
  lianxi_fangshi: '选择联系方式',
  lianxi_zhanghao: '联系账号',
  tuiguang_fangan:  '推广方案',
  tuiguang_fangan_tip:  '请描述您的推广计划',
  kefu: '客服',
  kefu_tg: 'Telegram客服',
  kefu_ws: 'WhatsApp客服',
  shuru_ninde: '输入您的',
  zhanghao: '账号',
  zhanghao_yiyou: '已有账号？',
  zhanghao_meiyou: '没有账号？',
  setup: '个人设置',
  touxiang: '头像',
  touxiang_xiugai: '修改头像',
  huobi: '货币',
  logout: '退出',
  username: '用户名',
  password: '密码',
  password_genggai: '更改密码',
  password_yuanshi: '原始密码',
  password_xin: '新密码',
  code: '邀请码',
  beizhu: '备注',
  beizhu_jine: '备注金额',
  beizhu_neirong: '备注内容',
  gengduo: '查看更多',
  gengduo_jiazai: '加载更多',
  qingxuanze: '请选择',
  sousuo_tip: '搜索用户名/UID',
  dingdanbianhao: '订单编号',
  chuangjianchenggong: '创建成功',
  zanwushuju: '暂无数据!',
  xiangqing: '详情',
  shuliang: '数量',
  xiangxibaobiao: '详细报表',
  shuru: '输入',
  laizi: '来自',
  zhuangtai: '状态',
  chulizhong: '处理中',
  chulizhong_shangji: '已提交，等待上级处理',
  yiwancheng: '已完成',
  shibai: '失败',
  wanjia: '玩家',
  wanjia_wode: '我的玩家',
  wanjia_xuanze: '选择玩家',
  wanjia_shuju: '玩家数据',
  wanjia_liebiao: '玩家列表',
  wanjia_xinzeng: '新增玩家',
  wanjia_xiangqing: '玩家详情',
  wanjia_xinxi: '玩家信息',
  daili: '代理',
  daili_wode: '我的代理',
  daili_xuanze: '选择代理',
  daili_shuju: '代理数据',
  daili_liebiao: '代理列表',
  daili_xinzeng: '新增代理',
  daili_xiangqing: '代理详情',
  daili_xinxi: '代理信息',
  bianji: '编辑',
  bianji_ziliao: '编辑资料',
  bianji_chenggong: '修改成功',
  jilu: '记录',
  jilu_lishi: '历史记录',
  jilu_jinqi: '近期记录',
  jilu_qingkong: '清空记录',
  dates: '日期',
  dates_today: '今天',
  dates_7: '7天',
  dates_30: '30天',
  dates_shaixuan: '筛选日期',
  youxi_shijian: '时间',
  youxi_touzhu: '投注',
  youxi_mingcheng: '游戏',
  youxi_paicai: '派彩',
  qsryhm: '请输入用户名',
  qsrmm: '请输入密码',
  qsrlxzh: '请输入联系账号',
  juli: '举例',
  jzgd: '加载更多',
  about_gg: ' 代理商招募介绍',
  about_tt: '代理招募',
  about_tip01: '轻松赚取',
  about_tip02: '高达',
  about_tip03: '利润',
  about_btn: '立即加入',
  about_a01: '利用在线赌场牟利的新机遇！',
  about_a02: '想从在线赌场现象中赚取更多利润？您面前有一个绝佳的机会。',
  about_a03: '您可以 20 雷亚尔的价格购买 100 点数，然后以 1:1 雷亚尔的比例出售给玩家。您已经知道，玩家在长时间的游戏中最终会亏损。当出现这种情况时，您就可以赚钱了！',
  about_a04: '玩家以 100 雷亚尔购买 100 点，如果输了，您将净赚 80 雷亚尔，因为您的初始支出只有 20 雷亚尔。',
  about_b01: '雇用二级代理为您销售！',
  about_b02: '您还可以更进一步，让其他人帮您销售。',
  about_b03: '作为一级代理，您有权以 10 雷亚尔的价格获得 100 积分。您可以招募新玩家并向他们出售积分。',
  about_b04: '但您还可以通过指定二级代理来扩大您的网络，您可以以 100 点 25 雷亚尔的价格向二级代理出售点数。',
  about_b05: '一级代理和二级代理之间可以协商这一价格。不过，您的二级代理仅限于向您（上级代理）购买积分。二级代理成功吸引更多玩家直接转化为积分销量的增加，从而提高您的利润率。',
  about_b06: '这样，随着您不断雇用新的代理向您销售，您的收入也会成倍增加！',
}
