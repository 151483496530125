<template>
  <div>
    <v-app-bar
      app
      flat
      color="app_bg"
    >
      <div class="d-flex align-center">
        <a href="home">
          <v-img
            class="shrink mr-2"
            contain
            src="@/assets/logo.svg"
            transition="scale-transition"
            width="120"
          />
        </a>
      </div>

      <v-spacer />
      <div class="d-none d-md-flex">
        <v-btn
          text
          to="home"
          class="mx-1"
        >
          <span>{{ $t('index') }}</span>
        </v-btn>

        <v-btn
          text
          to="MyPlayer"
          class="mx-1"
        >
          <span>{{ $t('wanjia') }}</span>
        </v-btn>

        <v-btn
          text
          to="MyAgent"
          class="mx-1"
        >
          <span>{{ $t('daili') }}</span>
        </v-btn>

        <v-btn
          text
          to="Record"
          class="mx-1"
        >
          <span>{{ $t('jilu') }}</span>
        </v-btn>
      </div>

      <template v-if="agentinfo.id>0">
        <div
          v-if="agentinfo.pid==0 && false"
          class="mx-1"
        >
          <v-chip
            small
            outlined
            color="#ddd"
            text-color="#666"
          >
            <strong>1</strong>
            <span class="mx-1">USDT</span>
            <v-icon small>
              mdi-swap-horizontal
            </v-icon>
            <strong class="mx-1">{{ exchange_rate }}</strong>
            <span>{{ agentinfo.currency }}</span>
          </v-chip>
        </div>

        <v-menu
          v-model="menu"
          max-width="230"
          offset-y
          transition="slide-y-reverse-transition"
          :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <v-card
            rounded="lg"
          >
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-img :src="require('../../assets/img/avatar/'+agentinfo.avatar+'.png')" />
                </v-list-item-avatar>

                <v-list-item-content :ref="'aginfo'">
                  <v-list-item-title>{{ agentinfo.username }}</v-list-item-title>
                  <v-list-item-subtitle>
                    UID:
                    <span>{{ agentinfo.id }}</span>
                    <v-btn
                      icon
                      @click="doCopy(agentinfo.id, 'aginfo')"
                    >
                      <v-icon small>
                        mdi-content-copy
                      </v-icon>
                    </v-btn>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="opacity-3" />

              <v-list-item to="Setup">
                <v-list-item-icon>
                  <v-icon>mdi-cog-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('setup') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="Record">
                <v-list-item-icon>
                  <v-icon>mdi-clock</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('jilu_lishi') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="opacity-3" />

              <v-list-item @click="logout">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('logout') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
    </v-app-bar>

    <v-main
      id="hide-on-scroll-main"
      class="pb-16 pb-sm-0"
    >
      <router-view />

      <v-footer padless>
        <v-col
          class="text-center opacity-6 mt-5"
          cols="12"
        >
          © {{ new Date().getFullYear() }} — <strong><span class="amber--text">Yelo</span>Bet</strong>.com
          <div class="my-2">
            <v-bottom-sheet
              v-model="sheet_kefu"
              inset
              max-width="600"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  depressed
                  rounded
                  small
                  color="grey lighten-2"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-headset
                  </v-icon>
                  {{ $t('kefu') }}
                </v-btn>
              </template>
              <v-sheet
                class="rounded-t-xl"
              >
                <v-toolbar
                  dense
                  flat
                  color="transparent"
                  class="pt-2"
                >
                  <v-btn
                    icon
                    @click="sheet_kefu = !sheet_kefu"
                  >
                    <v-icon>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                  <v-spacer />
                  <v-toolbar-title class="opacity-7">
                    {{ $t('kefu') }}
                  </v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
                <v-list class="transparent pb-6">
                  <v-list-item
                    href="https://wa.me/447599034922"
                    target="_blank"
                  >
                    <v-list-item-avatar>
                      <img src="../../assets/img/logos/WhatsApp.svg">
                    </v-list-item-avatar>
                    <v-list-item-subtitle>
                      {{ $t('kefu_ws') }}
                    </v-list-item-subtitle>
                    <v-list-item-icon-text>
                      <v-list-item-subtitle>
                        +44 7599 034922
                      </v-list-item-subtitle>
                    </v-list-item-icon-text>
                    <v-list-item-icon>
                      <v-icon>
                        mdi-share
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item
                    href="https://t.me/YeloBET"
                    target="_blank"
                  >
                    <v-list-item-avatar>
                      <img src="../../assets/img/logos/Telegram.svg">
                    </v-list-item-avatar>
                    <v-list-item-subtitle>
                      {{ $t('kefu_tg') }}
                    </v-list-item-subtitle>
                    <v-list-item-icon-text>
                      <v-list-item-subtitle>
                        @YeloBET
                      </v-list-item-subtitle>
                    </v-list-item-icon-text>
                    <v-list-item-icon>
                      <v-icon>
                        mdi-share
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-sheet>
            </v-bottom-sheet>
          </div>
        </v-col>
      </v-footer>
    </v-main>

    <v-bottom-navigation
      app
      grow
      hide-on-scroll
      scroll-target="#hide-on-scroll-main"
      height="65"
      :value="bottomvalue"
      color="primary"
      class="d-flex d-md-none"
    >
      <v-btn
        to="home"
      >
        <span>{{ $t('index') }}</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn
        to="MyPlayer"
      >
        <span>{{ $t('wanjia') }}</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>

      <v-btn
        to="MyAgent"
      >
        <span>{{ $t('daili') }}</span>
        <v-icon>mdi-account-tie</v-icon>
      </v-btn>

      <v-btn
        to="Record"
      >
        <span>{{ $t('jilu') }}</span>
        <v-icon>mdi-bullhorn</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import store from "../../store/"
export default {
  components:{

  },
  data: () => ({
    items: [
      { title: '🇨🇳 中文', lang: 'zhHans' },
      { title: '🇺🇸 English', lang: 'en' },
      { title: '🇧🇷 Português', lang: 'pt' },
      { title: '🇪🇸 Español', lang: 'es' }
    ],
    langs:{
      'zhHans':'中文',
      'en':'English',
      'pt':'Português',
      'es':'Español',
    },
    lang:'',
    bottomvalue: '',
    menu: false,
    sheet_kefu: false,
    agentinfo: {},
    confdata: {},
    exchange_rate: ''
  }),
  computed: {

  },
  watch: {
    '$store.state.agentinfo': function (newVal) {
      if (newVal) {
        this.agentinfo = this.$store.state.agentinfo
      }
    },
    '$store.state.exchange_rate': function (newVal) {
      this.exchange_rate = newVal
    },
  },
  created() {
    /*
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function('event.returnValue=false')
      // 禁用选择
      document.onselectstart = new Function('event.returnValue=false')
      // 禁止f12
      document.οnkeydοwn = new Function('event.returnValue=false')
    })
 
    // 上面的禁止f12那段代码没有生效，但是加了下面这段就能生效。
    document.onkeydown = function (e) {
      if (e && e.keyCode === 123) {
        e.returnValue = false
        // e.keyCode = 0   //去掉也可以的，倘若要写，则需要setter 以及 getter配合使用，不配合，会报错
        return false
      }
    }
    */
    
    this.$i18n.locale = sessionStorage.getItem('Lang')?sessionStorage.getItem('Lang'):'pt'
    this.$vuetify.lang.current = sessionStorage.getItem('Lang')?sessionStorage.getItem('Lang'):'pt'
    this.lang = this.langs[this.$i18n.locale]
  },
  mounted() {
    if(this.getStorage('agent_id')){
      this.myinfo()
      this.balancetimer = setInterval(() => {
        this.myinfo()
      }, 60000)
    }
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang
      this.$vuetify.lang.current = lang
      //localStorage.setItem('Lang', lang)
      sessionStorage.setItem('Lang', lang)
      this.lang = this.langs[lang]
      location.reload()
    },
    logout() {
      this.removeStorage("token")
      this.removeStorage("agent_id")
      this.agentinfo = {id:0}
      this.$router.go(0)
    },
    myinfo() {
      let paramObj = {
        agent_id: this.getStorage('agent_id'),
      }
      this.$server.myinfo(paramObj).then((response) => {
        if(response.code==200){
          this.agentinfo = response.data
          store.commit('agentinfo', this.agentinfo)
          this.conf()
        }else{
          this.$router.push({
            name: 'login',
          })
        }
      })
    },
    conf() {
      let paramObj = {
        agent_id: this.getStorage('agent_id'),
      }
      this.$server.conf(paramObj).then((response) => {
        if(response.code==200){
          this.confdata = response.data
          this.exchange_rate = response.data.exchange_rate[this.agentinfo.currency]
          store.commit('exchange_rate', this.exchange_rate)
        }
      })
    },
    doCopy: function (text, ref) {
      text = ''+text//转string
      this.$copyText(text, this.$refs[`${ref}`]).then( (e)=>{
        console.log(e)
        this.$snackbar.info(this.$t('fzcg'))
      }, function (e) {
        console.log(e)
      })
    },
  },
};
</script>

<style>
  @import '../../assets/css/style.css';
</style>