import Vue from "vue";
import Vuex from "vuex";
// 放置全局信息
Vue.use(Vuex);


export default new Vuex.Store({
  modules: {
    
  },
  state: {
    agentinfo: {},
    exchange_rate: ''
  },
  mutations: {
    agentinfo (state,data) {
      state.agentinfo = data
    },
    exchange_rate (state,data) {
      state.exchange_rate = data
    },
  },
  actions: {},
  getters: {}
});