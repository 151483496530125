export default {
copy_one: 'Click Copy',
copy_ok: 'Copy Success',
zdl: 'Got it!',
fen: 'Point',
fen_yue: 'Points Balance',
maifen: 'Buy Points',
pay_jine: 'Purchase amount',
pay_shurujine: 'Enter Purchase Amount',
pay_huode: 'Get points',
pay_fangshi: 'Select Payment Method',
pay_shuoming: 'Payment Instructions',
pay_xiane: 'Payment Limit',
pay_xiane_chao: 'Payment limit not met!',
pay_tijiao: 'Submit payment',
pay_queren: 'Confirm payment',
pay_usdt: 'Cryptocurrency Payment',
pay_usdt_address: 'Payment address',
pay_usdt_token: 'Transfer Token',
pay_usdt_chain: 'Network',
pay_usdt_tip: 'After the recharge is received, it will be automatically converted into game points according to the exchange rate.',
pay_usdt_done: 'Transfer completed',
pay_pix: 'PIX Wallet Payment',
pay_pix_tip: 'Please complete the payment within 10 minutes',
pay_pix_qr: 'Scan the QR code',
pay_pix_copy: 'Copy PIX CODE',
pay_pix_faq: 'How do I pay',
pay_pix_faq_ok: 'Open your bank or payment mobile app, navigate to the PIX Area, select "Pix Copy and Paste" or "Read QR code" and complete the payment!',
pay_pix_done: 'Payment Completed',
shangfen: 'Add Points',
shangfen_tip: 'Please contact the superior agent to add points.',
xiafen: 'Deduction of Points',
xiafen_shuliang: 'Number of points deducted',
xiafen_shuliang_shuru: 'Enter the amount of points to redeem',
zhuanfen: 'Transfer Points',
zhuanfen_to_wanjia: 'Transfer Points to Player',
zhuanfen_to_daili: 'Transfer Points to Agent',
zhuanfen_t2: 'Amount of transfer points',
zhuanfen_shuru: 'Enter the amount of points to transfer',
zhuanfen_done: 'Transfer successful',
jianfen: 'Deduction Points',
jianfen_t2: 'Deduction of Points',
jianfen_shuru: 'Enter deduction Points',
jianfen_chenggong: 'Deduction successful',
daiban: 'Pending',
xinzeng: 'Add new',
zhuanru: 'Transfer In',
zhuanru_zong: 'Total transfer in',
zhuanchu: 'Transfer out',
zhuanchu_zong: 'Total transfer out',
zhuanchu_jing: 'Net transfer out',
jine: 'Money',
jine_zong: 'Lump sum',
youxi: 'Game',
ren: 'Person',
genggai: 'Change',
tijiao: 'Submit',
tijiao_shijian: 'Submission time',
wancheng: 'Success',
jujue: 'Reject',
jujue_queren: 'Confirm the rejection?',
tongguo: 'Approve',
tongguo_queren: 'Confirm approval?',
quxiao: 'Cancel',
queding: 'Confirm',
index: 'Start',
login: 'Sign in',
login_last: 'Last login',
login_heying: 'Agent Panel',
reg: 'Register',
reg_liji: 'Sign up now',
reg_time: 'Registration time',
reg_heying: 'Register',
reg_wait01: 'Your account is awaiting approval...',
reg_wait02: 'Please contact Yelobet Support to processing:',
lianxi_fangshi: 'Contact Info',
lianxi_zhanghao: 'Contact Details',
tuiguang_fangan:  'What marketing strategies do you have?',
tuiguang_fangan_tip:  'Please describe your marketing plan',
kefu: 'Customer Service',
kefu_tg: 'Telegram Support',
kefu_ws: 'WhatsApp Support',
shuru_ninde: 'Enter your',
zhanghao: 'Account',
zhanghao_yiyou: 'Already have an account?',
zhanghao_meiyou: 'Do not have an account yet?',
setup: 'Settings',
touxiang: 'Avatar',
touxiang_xiugai: 'Modify avatar',
huobi: 'Currency',
logout: 'Sign out',
username: 'Username',
password: 'Password',
password_genggai: 'Change password',
password_yuanshi: 'Original password',
password_xin: 'New password',
code: 'Invitation Code',
beizhu: 'Remarks',
beizhu_jine: 'Remark amount',
beizhu_neirong: 'Remarks',
gengduo: 'View more',
gengduo_jiazai: 'Load more',
qingxuanze: 'Please select',
sousuo_tip: 'Search username/UID',
dingdanbianhao: 'Order number',
chuangjianchenggong: 'Created successfully',
zanwushuju: 'There is currently no data available!',
xiangqing: 'Details',
shuliang: 'Amount',
xiangxibaobiao: 'Detailed report',
shuru: 'Input',
laizi: 'From',
zhuangtai: 'Status',
chulizhong: 'Processing',
chulizhong_shangji: 'Submitted, waiting for superior processing',
yiwancheng: 'Completed',
shibai: 'Fail',
wanjia: 'Player',
wanjia_wode: 'My Players',
wanjia_xuanze: 'Select player',
wanjia_shuju: 'Player Dashboard',
wanjia_liebiao: 'Player List',
wanjia_xinzeng: 'New Players',
wanjia_xiangqing: 'Player details',
wanjia_xinxi: 'Player Information',
daili: 'Agent',
daili_wode: 'My Agent',
daili_xuanze: 'Select Agent',
daili_shuju: 'Agent Dashboard',
daili_liebiao: 'Agents List',
daili_xinzeng: 'New Agent',
daili_xiangqing: 'Agent details',
daili_xinxi: 'Agent Information',
bianji: 'Edit',
bianji_ziliao: 'Editing Information',
bianji_chenggong: 'Modified successfully',
jilu: 'Record',
jilu_lishi: 'History',
jilu_jinqi: 'Recent records',
jilu_qingkong: 'Clear Records',
dates: 'Date',
dates_today: 'Today',
dates_7: '7 days',
dates_30: '30 days',
dates_shaixuan: 'Filter date',
youxi_shijian: 'Time',
youxi_touzhu: 'Betting',
youxi_mingcheng: 'Game',
youxi_paicai: 'Payout',
qsryhm: 'Please enter your username',
qsrmm: 'Please enter your password',
qsrlxzh: 'Please enter your contact account',
juli: 'Example',
jzgd: 'More',
about_gg: 'New Opportunity to Leverage Your Earnings with Online Casinos!',
about_tt: 'Agent recruitment',
about_tip01: 'Easily earn',
about_tip02: 'up to',
about_tip03: 'profit',
about_btn: 'Join now',
about_a01: 'New Opportunity to Leverage Your Profits with Online Casinos!',
about_a02: 'Want to make even more money from the online casino phenomenon? You have a great opportunity in front of you.',
about_a03: 'You can purchase 100 points for R$20 and subsequently sell them to players at a 1:1 R$ ratio. You already know that players will eventually lose money over extended play periods. When that happens, you are going to make money!',
about_a04: 'For example, if one of your players buys 100 points from you for R$100 and loses, you make a profit of R$80, since your initial outlay was only R$20',
about_b01: 'Hiring Secondary Agents to Sell for You!',
about_b02: 'You can go even deeper and get other people to sell to you.',
about_b03: 'As a level 1 agent, you empowered to acquire 100 points at the cost of R$20. You can recruit new players and sell points to them.',
about_b04: 'But you can go beyond and expand your network by appointing secondary agents to whom you can sell points at a rate of R$25 for 100 points.',
about_b05: 'This rate is negotiable between primary and secondary agents. However, your secondary agents are restricted to purchasing points exclusively from you (the superior agent). The success of your secondary agents in attracting more players translates directly into increased sales of points, thereby elevating your profit margins.',
about_b06: 'With this, you are increasing your earnings exponentially as you keep hiring new agents to sell to you!',
}