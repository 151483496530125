import Vue from 'vue'
import VueRouter from 'vue-router'
import { DefaultLayout } from '@/components/layouts'
import {getStorage, removeStorage, getDeviceId} from "../module/storage"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    redirect: {name:'Home'},
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
      },
      {
        path: '/deposit_pix',
        name: 'Deposit_pix',
        component: () => import(/* webpackChunkName: "Deposit_pix" */ '../views/Deposit_pix.vue')
      },
      {
        path: '/deposit',
        name: 'Deposit',
        component: () => import(/* webpackChunkName: "Deposit" */ '../views/Deposit.vue')
      },
      {
        path: '/withdraw',
        name: 'Withdraw',
        component: () => import(/* webpackChunkName: "Withdraw" */ '../views/Withdraw.vue')
      },
      {
        path: '/approval',
        name: 'Approval',
        component: () => import(/* webpackChunkName: "Approval" */ '../views/Approval.vue')
      },
      {
        path: '/team',
        name: 'Team',
        component: () => import(/* webpackChunkName: "Team" */ '../views/Team.vue')
      },
      {
        path: '/addagent',
        name: 'AddAgent',
        component: () => import(/* webpackChunkName: "AddAgent" */ '../views/AddAgent.vue')
      },
      {
        path: '/addplayer',
        name: 'AddPlayer',
        component: () => import(/* webpackChunkName: "AddPlayer" */ '../views/AddPlayer.vue')
      },
      {
        path: '/myplayer',
        name: 'MyPlayer',
        component: () => import(/* webpackChunkName: "MyPlayer" */ '../views/MyPlayer.vue')
      },
      {
        path: '/myplayer_detail',
        name: 'MyPlayer_Detail',
        component: () => import(/* webpackChunkName: "MyPlayer_Detail" */ '../views/MyPlayer_Detail.vue')
      },
      {
        path: '/myplayer_report',
        name: 'MyPlayer_Report',
        component: () => import(/* webpackChunkName: "MyPlayer_Report" */ '../views/MyPlayer_Report.vue')
      },
      {
        path: '/myagent',
        name: 'MyAgent',
        component: () => import(/* webpackChunkName: "MyAgent" */ '../views/MyAgent.vue')
      },
      {
        path: '/myagent_detail',
        name: 'MyAgent_Detail',
        component: () => import(/* webpackChunkName: "MyAgent_Detail" */ '../views/MyAgent_Detail.vue')
      },
      {
        path: '/myagent_report',
        name: 'MyAgent_Report',
        component: () => import(/* webpackChunkName: "MyAgent_Report" */ '../views/MyAgent_Report.vue')
      },
      {
        path: '/record',
        name: 'Record',
        component: () => import(/* webpackChunkName: "Record" */ '../views/Record.vue')
      },
      {
        path: '/setup',
        name: 'Setup',
        component: () => import(/* webpackChunkName: "Setup" */ '../views/Setup.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "About" */ '../views/About.vue')
      },
    ]
  },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/Index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/reg',
    name: 'reg',
    component: () => import(/* webpackChunkName: "reg" */ '../views/Reg.vue')
  },
  {
    path: '/reg_wait',
    name: 'regwait',
    component: () => import(/* webpackChunkName: "regwait" */ '../views/Reg_Wait.vue')
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name == "reg" || to.name == "regwait" || to.name == "login" || to.name == "index" || to.name == "About") {
    next()
  }else{
    if(getStorage('agent_id')>0){
      next()
    }else{
      next({
        name:"login",
        //query: {redirect: to.fullPath}//将目的路由地址存入login的query中
      })
    }
  }
})

router.afterEach((to, from) => {
  
})

export default router
